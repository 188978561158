.App {
  /* display: flex;
  justify-content: center;
  align-itrems: center; */
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  background-color: #0a192f;
}

/* .noise {
  opacity: 0.2;
  inset: -200%;
  width: 400%;
  height: 400%;
  position: absolute;
  background-size: 256px 256px;
  background-repeat: repeat;
  background: url('https://framerusercontent.com/images/rR6HYXBrMmX4cRpXfXUOvpvpB0.png');
} */

p {
  color: white;
  font-size: 1.2rem;
}

h1 {
  font-size: 3rem;
  margin: 0;
}

h2 {
  color: #1890ff;
  font-size: 1.8rem;
  margin: 0;
}

h3 {
  color: #1890ff;
  font-size: 1.5rem;
  margin: 0;
}

@media only screen and (max-width: 1200px) {
  p {
    font-size: 1.1rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1080px) {
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.3rem;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  p {
    font-size: 1rem;
  }
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.2rem;
    font-weight: normal;
  }
  h3 {
    font-size: 1.1rem;
    font-weight: normal;
  }
}

@media (max-width: 480px) {
  p {
    font-size: 0.8rem;
    line-height: 1.5rem;
  }
  h1 {
    font-size: 1.3rem;
  }
  h2 {
    font-size: 1.15rem;
    font-weight: normal;
  }
  h3 {
    font-size: 0.95rem;
    font-weight: normal;
  }
}
