.logo {
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

@media only screen and (max-width: 768px) {
  .btn {
    font: italic bold 1.3vh "Courier New";
    color: white;
    cursor: pointer;
  }
  a {
    .resumeBtn {
      height: 40px;
      width: 40px;
      color: #1890ff;
      background-color: transparent;
      font: 1.3vh "Courier New";
      border: 1px solid #2e5a88;
      border-radius: 5px;
      cursor: pointer;
      transform: translateX(0px) translateY(0px);
      transition: transform 250ms;

      &:hover {
        color: white;
        transform: translateX(-2px) translateY(-2px);
        transition: transform 250ms;
        box-shadow: 1px 1px 2px white;
      }
    }
  }
}
