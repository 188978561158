.btn {
  font: bold 2vh "Courier New";
  color: white;
  cursor: pointer;
  transform: scale(1) rotate(0deg) translate(0px, 0px) skew(-10deg, 0deg);
}

a {
  .button {
    height: 40px;
    width: 80px;
    background-color: transparent;
    color: #1890ff;
    font: bold 2vh "Courier New";
    border: 1px solid #2e5a88;
    border-radius: 5px;
    margin-left: 40px;
    cursor: pointer;

    &:hover {
      color: white;
    }
  }

  /* > a {
    text-decoration: none;
    color: white;
  } */
}

.logo {
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

@media only screen and (max-width: 768px) {
  .btn,
  .button {
    font: italic bold 1.3vh "Courier New";
    color: white;
    cursor: pointer;
  }
}

@media only screen and (max-width: 650px) {
  a > .button {
    font: italic bold 1.3vh "Courier New";
    margin-left: 20px;
    width: 40px;
  }
}

@media only screen and (min-width: 560px) {
  a > .button {
    transform: translateX(0px) translateY(0px);
    transition: transform 250ms;
    &:hover {
      opacity: 0.8;
      transform: translateX(-2px) translateY(-2px);
      transition: transform 250ms;
      box-shadow: 1px 1px 2px white;
    }
  }
}
