/* Importing google font  */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;600;700;800;900&display=swap');

.textSphere {
    position: relative;
    top: 0;
    width: 380px;
    height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1e1e1e;
}

.tagcloud {
    display: inline-block;
    top: 0;
    left: 0;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    letter-spacing: 0.0625em;
    font-size: 1em;
}

/* Change color of each text in sphere on hover   */
.tagcloud--item {
    color: #00ffffff;
}

.tagcloud--item:hover {
    color: blue;
    cursor: default;
}