.container {
  position: fixed;
  display: flex;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-color: black;
  align-items: center;
  justify-content: center;
}

.enterbtn {
  position: fixed;
  width: 200px;
  height: 50px;
  border: 1px solid white;
  background-color: blue;
  color: white
}

.main {
  position: relative;
  z-index: 100;
  width: 200px;
  height: 50px;
  border-radius: 5px;
  border: 2px solid white;
  overflow: hidden;
}

.fill {
  /* position: absolute;
  top: 50;
  left: 0; */
  width: 100%;
  height: 100%;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
