.skillsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
  /* align-items: flex-start; */
  text-align: left;
  /* gap: 40px; */
}

.TabPanel {
  /* min-height: 200px; */
  max-width: 520px;
  /* height: 464px; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  column-gap: 10px;
  row-gap: 10px;
  grid-template-rows: repeat(auto, 50px);
  align-items: center;
  justify-content: center;
}

.tab {
  color: white;
}
.separator {
  opacity: 0.8;
  border-bottom: 1px solid #2e5a88;
  margin-top: 10px;
}

.chip {
  background-color: transparent;
  border: 1px solid black;
  color: salmon;
  padding: 5px;
  width: 100%;
  border-radius: 15%/50%;
  text-align: center;
}
@media (max-width: 480px) {
  .TabPanel {
    min-width: 280px;
    height: 280px;
    grid-template-columns: repeat(auto-fill, minmax(60px, 100px));
    justify-content: center;
  }
  .chip {
    font-size: 0.8rem;
  }
}
