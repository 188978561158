*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  /* height: 100%;
  width: 100%; */
  margin: 0;
  padding: 0;
}

div#root {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
}

body {
  margin: 0;
  padding: 0;
  /* display: block; */
  min-height: 100vh;
  width: 100%;
  color: white;
  /* font-family: "utopia-std-headline", serif; */
  font-weight: 400;
  font-style: normal;
  font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", monospace;
  /* font-family: "SF Mono","Fira Code","Fira Mono","Roboto Mono",monospace */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  /* font-family: "utopia-std-headline", serif; */
  font-weight: 400;
  font-style: normal;
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}
