.jobsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}

.jobWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.job {
  display: flex;
  min-height: 300px;
  justify-content: space-between;
  align-items: center;
}

.title {
  min-height: 80px;
  line-height: 1.5em;
  > p {
    margin: 0px;
  }
  /* padding: 10px 30px; */
}

.separator {
  opacity: 0.8;
  border-bottom: 1px solid #2e5a88;
  margin-top: 10px;
}

.role,
.date {
  color: salmon;
}

/* .role {
  font-size: 1.5em;
} */

.summary {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  /* padding: 20px; */
  font-size: 1.2rem;
  &:last-of-type {
    width: 100%;
  }
}

.stack {
  margin-top: 20px;
  font-size: 15px;
  color: salmon;
  font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", monospace;
}

.imgWrapper {
  height: 100%;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* img {
  height: ;
  width: 100%;
} */

.anchorWrapper {
  height: 300px;
  width: 50%;

  /* //min-width: 150px; */
  transform: scale(1);
  transition: transform 800ms;
  &:hover {
    transform: scale(1.08);
    transition: transform 800ms;
    cursor: pointer;
  }
}

.imageWrapper {
  height: 100%;
  width: 100%;
  padding: 10px;
  min-width: 150px;
  display: flex;
  justify-content: center;
}

@media (min-width: 1080px) {
  .summary {
    width: 50%;
  }

  .anchorWrapper {
    width: 50%;
  }
}

@media (max-width: 1080px) {
  .job {
    display: block;
  }
  .summary,
  .anchorWrapper,
  .imageWrapper {
    width: 100%;
    height: auto;
  }
  .stack {
    font-size: 13px;
    word-wrap: normal;
  }
}
