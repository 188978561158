.landingWrapper {
  /* display: grid;
  grid-template-rows: 60px 200px repeat(11, 350px) 60px;
  grid-template-columns: 1fr repeat(6, minmax(min-content, 14rem)) 1fr; */
  /* min-height: 100vh; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0a192f;
  /* grid-template-rows: 80vh min-content 40vw repeat(3, min-content);
  grid-template-columns: [sidebar-start] 8rem [sidebar-end full-start] 1fr [center-start] repeat(8, [col-start]) minmax((min-contnet, 14rem) [col-end]) [center-end] 1fr [full-end]; */
}

.background {
  background-size: contain;
  z-index: 1;
  opacity: 0.4;
}
.landingPage {
  max-width: 1000px;
}

section {
  margin: 20px 0px;
}
/* #0a192f; */
/* .content {
  margin-top: 60px;
} */

/* E3E8F8
018CFE
3776FD
0AC1FD */

/* 
.Default {
  background-color:  #018CFE;
  opacity: 1;
} */

/* .Loading {
  opacity: 0;
} */

.roleWrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 90px 0px;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: 'Courier New';
  margin-bottom: 40px;

  .roleName {
    font-size: 20px;
  }
  /* grid-column: 1 / -1;
  grid-row: 2 /3; */
}

.typingBorder {
  color: white;
}

.headshotWrapper {
  /* height: 300px;
  width: 300px; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;

  > img {
    transition: all 0.3s ease-in-out;
    border-radius: 0%;
    width: 100%;

    &:hover {
      border-radius: 100%;
      scale: 1.1;
    }
  }
}

.separator {
  opacity: 0.8;
  border-bottom: 1px solid #2e5a88;
  margin-top: 10px;
}

.about {
  /* min-height: 600px; */
  width: 100%;
  min-width: 260px;
  /* grid-row: 3 / 5;
  grid-column: 2 / 8; */
  /* display: grid;
  grid-template-columns: 3fr 2fr; */
  /* place-items: center; */
  background-color: #112240;

  gap: 10px;
  padding: 20px;
  /* border-radius: 30px; */
}

.aboutContent {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 20px;

  > div > p {
    > b {
      color: #1890ff;
    }
    > a {
      color: #1890ff;
      font-weight: bold;
      text-decoration: none;
      margin-left: 10px;

      &:hover {
        color: salmon;
      }
    }
  }
}

.aboutLangs {
  display: grid;
  grid-template-columns: repeat(2, minmax(80px, 150px));
  place-content: left;
  padding: 0px;
  gap: 10px;
  list-style: none;

  > li {
    color: salmon;
    font-size: 16px;

    &:before {
      content: '>';
      color: white;
      margin-right: 5px;
    }
  }
}

.experience {
  width: 100%;
  min-width: 260px;
  display: grid;
  grid-template-rows: repeat(1fr);
  place-items: center;
  padding: 20px;
  /* grid-column: 2 / 8;
  grid-row: 5 / 9; */
  background-color: #112240;
  /* border-radius: 30px; */
}

.skills {
  width: 100%;
  min-width: 260px;
  /* display: grid;
  place-items: stretch; */
  /* grid-column: 2 /8;
  grid-row: 9 /10; */
  text-align: center;
  padding: 0px 50px;
  background-color: #112240;
  /* border-radius: 30px; */
}

.skillsRow {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.interests {
  width: 100%;
  min-width: 260px;
  display: flex;
  padding: 20px;
  /* display: grid;
  place-items: center; */
  background-color: #112240;
  /* grid-column: 2 / 8;
  grid-row: 10 / 11; */
  /* border-radius: 30px; */
}

.projectsWrapper {
  width: 100%;
  min-width: 260px;
  background-color: #112240;
  padding: 20px;

  /* .separator {
    margin: 10px 20px;
  } */
  /* grid-row: 11 / 13;
  grid-column: 2 / 8; */
  /* display: grid; */
  /* grid-template-rows: 60px 440px 440px;
  grid-template-columns: auto; */
  /* gap: 10px;
  padding: 20px;
  margin: 20px 0; */
  /* border-radius: 30px; */
}

.contacts {
  width: 100%;
  min-width: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #112240;
  padding: 20px;
  margin-bottom: 50px;

  > p {
    text-align: center;
    width: 70%;
  }

  > a {
    .messageBtn {
      height: 60px;
      width: 120px;
      background-color: transparent;
      color: #1890ff;
      border: 1px solid #1890ff;
      border-radius: 5px;
      cursor: pointer;
      transform: translateX(0px) translateY(0px);
      transition: transform 250ms;
      font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;

      &:hover {
        transform: translateX(-2px) translateY(-2px);
        transition: transform 250ms;
        box-shadow: 1px 1px 2px white;
        color: white;
      }
    }
  }
}

.project {
  display: grid;
  height: 400px;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  /* grid-template-rows: 1fr 3fr 1fr; */
  /* background-color: pink; */
  margin: 50px 0px;
  gap: 10px;
  /* .projectTitle {
    grid-column: 9 / -1;
    grid-row: 1 /2;
    place-content: center;
    text-align: right;
  } */
  /* grid-row: 9 / 10;
  grid-column: 2 /8; */
}

.luxury {
  grid-row: 1 /-1;
  grid-column: 1 / 9;
  display: grid;
  place-content: center;
  background-color: #495670;

  opacity: 0.6;
  transition: transform 800ms;
  &:hover {
    opacity: 1;
    transition: opacity 0.5s;
    cursor: pointer;
    /* transform: scale(1.08);
    transition: transform 800ms;
    cursor: pointer; */
  }
}
.luxuryTitle {
  grid-row: 2/4;
  grid-column: 9 / -1;
  text-align: right;
}
/* .luxuryDescriptionWrapper {
  grid-row: 1 / -1;
  grid-column: 6 / -1;
  z-index: 10;

} */

.luxuryDescription {
  background-color: #112240;
  box-shadow: 0 10px 30px -15px rgba(2, 12, 27, 0.7);
  grid-row: 4 / 10;
  grid-column: 6 / -1;
  z-index: 10;
  padding: 20px 20px;
}

.calculator {
  grid-row: 1 / -1;
  grid-column: 6/ 13;
  display: grid;
  place-content: center;
  background-color: #495670;

  opacity: 0.6;
  transition: transform 800ms;
  &:hover {
    opacity: 1;
    transition: opacity 0.5s;
    cursor: pointer;
    /* transform: scale(1.08);
    transition: transform 800ms;
    cursor: pointer; */
  }
}

.calculatorTitle {
  grid-row: 2/4;
  grid-column: 1 / 9;
  text-align: left;
}

.calculatorDescription {
  grid-row: 3 / 11;
  grid-column: 1/ 8;
  z-index: 10;
  padding: 20px;
  background-color: #112240;
  box-shadow: 0 10px 30px -15px rgba(2, 12, 27, 0.7);
}

/* .col1 {
  background-color: green;
  > h3 {
    color: #0a192f;
  }
  color: #0a192f;
  grid-column: 1 / 3;
  grid-row: 1 /3;
} */

/* .col2 {
  grid-column: 3 / 6;
  grid-row: 3 /6;
  width: 50%;
} */

/* .projectItem:nth-child(2) {
 
} */
.anchorWrapper {
  /* height: 300px; */
  width: 100%;
  min-width: 150px;
  /* transform: scale(1);
  transition: transform 800ms;
  &:hover {
    transform: scale(1.08);
    transition: transform 800ms;
    cursor: pointer;
  } */
}

projectDescription {
  /* box-shadow: 10px 5px 10px gray; */
  padding: 20px;
  border: 1px solid black;
  color: black;
  background-color: #f9f9f9;
  text-align: left;
}
.projectLangs {
  color: salmon;
  display: flex;
  justify-content: space-between;
  width: 100%;

  /* width: 80%;
  display: flex;
  justify-content: space-evenly;
  align-items: center; */
}

.projectLinks {
  height: 20px;
  width: 60px;
  /* margin-left: 10px; */
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    &:hover {
      transform: translateY(-3px);
      transition: transform 0.25s;
    }
    > a {
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }
  }
}

.contactLinks {
  width: 40px;
  position: fixed;
  left: calc((100vw - 1000px) / 4);
  /* left: 80px; */
  bottom: 0px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  margin: 0px;
  /* > svg {
    fill: white;
  } */

  > div {
    padding: 5px 0px;
    transform: Y(3px);
    transition: transform 0.25s;
    &:last-of-type {
      margin-bottom: 20px;
    }
    &:hover {
      transform: translateY(-3px);
      transition: transform 0.25s;
    }
    > a {
      opacity: 0.8;
    }
    > a:hover {
      opacity: 1;
    }
  }
}

.mobileContactLinks {
  /* height: 100px; */

  width: 100%;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;

  > div {
    padding: 5px 0px;
    transform: Y(3px);
    transition: transform 0.25s;

    &:hover {
      transform: translateY(-3px);
      transition: transform 0.25s;
    }
    > a {
      opacity: 0.8;
    }
    > a:hover {
      opacity: 1;
    }
  }
}

.emailWrapper {
  width: 40px;
  /* grid-column: 8 / -1; */
  position: fixed;
  right: 75px;
  bottom: 0px;
  z-index: 10;

  padding: 0px;
  margin: 0px;
}

.email {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;
  /* transform: translateY(2px);
  transition: transform 0.25s; */

  > a {
    color: #2e5a88;
    opacity: 0.7;
    margin-bottom: 20px;
    position: relative;
    text-decoration-skip-ink: auto;
    display: inline-block;
    writing-mode: vertical-rl;
    text-decoration: none;
    transform: translateY(2px);
    transition: transform 0.25s;
    font-size: 16px;
    letter-spacing: 0.1em;

    &:hover {
      opacity: 1;
      transform: translateY(-2px);
      transition: transform 0.25s;
    }
  }
}

.contactLinks::after,
.email::after {
  content: '';
  display: block;
  width: 1px;
  height: 90px;
  margin: 0px auto;
  background-color: #2e5a88;
}

.footerWrapper {
  height: 30px;
  margin-top: 20px;
  text-align: center;
}
/* .footer {
  position: fixed;
  bottom: 0;
} */

.imageWrapper {
  height: 300px;
  width: 100%;
  min-width: 150px;
  display: flex;
  justify-content: center;
}

img {
  height: auto;
  /* max-width: 80%; */
  /* object-fit: cover; */
  /* border-radius: 20px; */
}
@media (max-width: 1440px) {
  .jobW {
    display: block;
    > img {
      max-width: 80%;
    }
  }
}

@media (max-width: 1200px) {
  .luxuryDescription {
    grid-row: 4 / 10;
    grid-column: 4 / -1;
    padding: 20px 20px;
  }
}

@media (min-width: 1080px) {
  .landingWrapper {
    padding: 0px 200px;
  }
}

@media (min-width: 768px) and (max-width: 1080px) {
  .landingWrapper {
    padding: 0px 100px;
  }
  /* .about {
    background-color: red;
  } */
  /* .experience {
    display: block;
  } */

  .emailWrapper {
    right: 30px;
  }
  .contactLinks {
    left: 30px;
  }
  img {
    max-width: 80%;
  }
}

@media (max-width: 768px) {
  .landingWrapper {
    padding: 0px 100px;
  }
  .aboutContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .aboutLangs {
    > li {
      font-size: 12px;
    }
  }
  .headshotWrapper {
    height: 300px;
    width: 300px;
  }
  .projectsWrapper {
    padding: 20px 0px 0px 0px;
    > h2 {
      margin-left: 50px;
    }
    .separator {
      margin: 10px 50px;
    }
  }
  .project {
    box-shadow: 0 10px 30px -15px rgba(2, 12, 27, 0.7);
    height: 360px;
    margin: 0;
    padding: 25px 50px;
    background-color: #112240;
  }
  .luxury,
  .luxuryDescription,
  .calculator,
  .calculatorDescription {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    background-color: #112240;
    opacity: 0.9;
    box-shadow: none;
  }
  .luxuryTitle,
  .calculatorTitle {
    grid-row: 1 /2;
    grid-column: 1 / 8;
    z-index: 11;
  }

  .luxuryDescription,
  .calculatorDescription {
    padding: 0;
  }
  .luxuryTitle,
  .calculatorTitle {
    text-align: left;
  }

  .luxuryDescription,
  .calculatorDescription {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  /* .projectLangs {
    justify-content: flex-start;
  } */
  .projectLinks {
    > a {
      opacity: 0.85;
      &:hover {
        opacity: 1;
      }
    }
  }
}

@media (max-width: 650px) {
  .skillsRow {
    flex-direction: column;
  }
  .projectsWrapper {
    > h2 {
      margin-left: 25px;
    }
    .separator {
      margin: 10px 25px;
    }
  }
  .project {
    padding: 25px;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .landingWrapper {
    padding: 0px 50px;
  }
  .skills {
    padding: 0;
  }
}
@media (max-width: 480px) {
  .landingWrapper,
  .skills {
    padding: 0 10px;
  }

  .landingPage {
    max-width: 400px;
  }

  .projectsWrapper {
    > h2 {
      margin-left: 15px;
    }
    .separator {
      margin: 10px 15px;
    }
  }
  .project {
    padding: 15px;
  }
  .about {
    /* display: block; */
    min-height: 600px;
    /* width: 420px; */
  }
  .aboutLangs {
    gap: 0px;
  }
  .contacts {
    > a {
      .messageBtn {
        width: 100px;
      }
    }
  }
  img {
    max-width: 90%;
  }
  .skillsRow {
    flex-direction: column;
  }
  .footer {
    font-size: 0.8rem;
  }
}

@media (max-width: 450px) {
  .landingPage {
    max-width: 300px;
  }
  .projectLangs {
    > div:first-child {
      width: 80%;
      font-size: 12px;
    }
  }
  .projectLinks {
    width: 50px;
  }
}
