/* Importing google font  */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;600;700;800;900&display=swap");

.textSphereLg {
  position: relative;
  top: 0;
  width: 460px;
  height: 460px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1e1e1e;
}

.tagcloudLg {
  display: inline-block;
  top: 0;
  left: 0;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: 0.0625em;
  font-size: 1.1em;
}

/* Change color of each text in sphere on hover   */
.tagcloudLg--item {
  color: #00ffffff;
}

.tagcloudLg--item:hover {
  color: blue;
  cursor: default;
}
